define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["de-de", {
    "18YearsOrAbove": "18 years of age or above",
    "Active": "Aktiv",
    "Add Event": "Ereignis hinzufügen",
    "Admin": "Administrator",
    "Assets": "Vermögenswerte",
    "Audio Visual": "Audiovisuelles",
    "Available Films": "Verfügbare Filme",
    "Available Pictures": "Verfügbare Bilder",
    "Available Posters": "Verfügbare Poster",
    "Behind The Scenes": "Hinter den Kulissen",
    "Calendar": "Kalender",
    "Chinese": "Chinesisch",
    "Coming Soon": "Kommt bald",
    "Content Management": "Inhaltsverwaltung",
    "Credit": "Gutschrift",
    "Date": "Datum",
    "Date Available": "Datum verfügbar",
    "Date Expires": "Datum läuft ab",
    "Description": "Beschreibung",
    "Directed By": "Unter der Regie von",
    "Download All": "Alles herunterladen",
    "Downloads": "Downloads",
    "Edit Event": "Ereignis bearbeiten",
    "Embargo": "Embargo",
    "Embargo Agreement Date": "Datum der Embargovereinbarung",
    "Embargo In Effect": "Embargo in Kraft",
    "Embargo Release Date": "Veröffentlichungsdatum des Embargos",
    "Embargo Start Date": "Startdatum des Embargos",
    "End Date": "Endtermin",
    "English": "English",
    "Event Location": "Veranstaltungsort",
    "Event Name": "Veranstaltungsname",
    "Event Type": "Ereignistyp",
    "Events": "Veranstaltungen",
    "Events Management": "Eventmanagement",
    "Exclusives": "Exklusiv",
    "Featured Film": "Spielfilm",
    "Featured Films": "Spielfilme",
    "Film": "Film",
    "Film Information": "Filminformationen",
    "French": "Französisch",
    "German": "Deutsch",
    "Global": "Global",
    "Headline": "Überschrift",
    "Headquarters": "Hauptquartier",
    "Home": "Startseite",
    "Inactive": "Inaktiv",
    "Instruction": "Anweisung",
    "Japanese": "Japanisch",
    "Journalists Management": "Journalistenmanagement",
    "Journalists Website": "Journalisten-Website",
    "Last Updated At": "Zuletzt aktualisiert um",
    "List of Movies": "Liste der Filme",
    "Local": "Lokal",
    "Local Title": "Lokaler Titel",
    "Localized": "Lokalisiert",
    "Marketing Automation": "Marketing-Automatisierung",
    "Materials": "Materialien",
    "Messages": "Mitteilungen",
    "New": "Neu",
    "New Assets": "Neue Vermögenswerte",
    "New Local Title": "Neuer lokaler Titel",
    "New Materials": "Neue Materialien",
    "New Release Date": "Neues Veröffentlichungsdatum",
    "New Running Time": "New Running Time",
    "News": "Nachrichten",
    "Our Country": "Unser Land",
    "Please click on": "Bitte klicken Sie auf",
    "Posters": "Poster",
    "Privacy Policy": "Datenschutz-Bestimmungen",
    "Production": "Produktion",
    "Rated": "Bewertet",
    "Release Date": "Veröffentlichungsdatum",
    "Release Dates": "Erscheinungsdaten",
    "SAVE AMERICA": "Rette AMERIKA",
    "Select Language": "Sprache auswählen",
    "Selected Picture": "Ausgewähltes Bild",
    "Selected Poster": "Ausgewähltes Poster",
    "Share Screen": "Bildschirm teilen",
    "Special Shoots": "Sonderaufnahmen",
    "Start Date": "Startdatum",
    "Status": "Status",
    "Summary": "Zusammenfassung",
    "Synopsis": "Zusammenfassung",
    "Terms and Conditions": "Geschäftsbedingungen",
    "Territory": "Territory",
    "Timeline": "Zeitleiste",
    "Title": "Titel",
    "Topics": "Thema",
    "Upcoming Events": "Kommende Veranstaltungen",
    "Upload": "Hochladen",
    "Upload Assets": "Assets hochladen",
    "Use localized": "Lokalisiertes verwenden",
    "Users Management": "Benutzerverwaltung",
    "Video Conference": "Videokonferenz",
    "Welcome": "Willkommen",
    "Writer": "Schriftsteller",
    "Written": "Geschrieben",
    "active": "Active",
    "activePoster": "Active Poster",
    "address": "Address",
    "adkSelectDropdown.selectAnOption": "Select an option",
    "agreeOn": "agree on",
    "app.admin": "Admin",
    "app.chinese": "Chinese",
    "app.embargo": "Embargo",
    "app.english": "English",
    "app.french": "French",
    "app.german": "German",
    "app.headquarters": "Headquarters",
    "app.home": "Home",
    "app.japanese": "Japanese",
    "app.journalistsWebsite": "Journalists Website",
    "app.korean": "Korean",
    "app.listOfMovies": "List of Movies",
    "app.timeline": "Timeline",
    "approvedBy": "Approved by",
    "approvedComment": "Approved Comment",
    "audioVisual": "Audio Visual",
    "availablePosters": "Available Posters",
    "back": "Back",
    "behindTheScenes": "Behind The Scenes",
    "cancel": "Cancel",
    "cancelConfirm": "Are you sure to cancel the changes (data will be reset)?",
    "check": "Check",
    "citizenOf": "Citizen Of",
    "city": "City",
    "close": "Close",
    "companyEmail": "Company Email",
    "companyInfo": "Company Information",
    "companyName": "Company Name",
    "confirmCancelOperation": "Are you sure to cancel this operation?",
    "confirmCreateTerritoryOffice": "Are you sure to create this territory office?",
    "confirmMoveOperation": "Are you sure to move this user?",
    "confirmPassword": "Confirm Password",
    "confirmRemoveAsset": "Are you sure to remove this asset?",
    "confirmSendOperation": "Are you sure to send this email?",
    "confirmUpdateTerritoryOffice": "Are you sure to update this territory office?",
    "contact": "Contact",
    "copyToLocal": "Copy to local",
    "country": "Country",
    "countryOfOrigin": "Country of Origin",
    "countryOfOriginSelect": "Please select country of origin",
    "credit": "Credit",
    "date": "Date",
    "dateApproved": "Date Approved",
    "dateAvailable": "Date Available",
    "dateCreated": "Date created",
    "dateExpires": "Date Expires",
    "dateSentIn": "Date sent in",
    "description": "Description",
    "directedBy": "Directed By",
    "director": "Director",
    "done": "Done",
    "downloadAll": "Download All",
    "draft": "Draft",
    "edit": "Edit",
    "email": "Email",
    "emailIsAllow": "Email is allowed",
    "errorDateBigger": "date expire should be greater than date available",
    "errorDateSmaller": "date expire should be smaller than date available",
    "errors": {
      "acknowledge-empty": "*You must agree with these agreement",
      "citizenship-empty": "*Citizenship is required",
      "companyAddress-empty": "*Address is required",
      "companyCity-empty": "*City is required",
      "companyName-empty": "*Company name is required",
      "companyState-empty": "*State is required",
      "country-empty": "*Country is required",
      "email-empty": "*Email address is required",
      "email-regex": "*Email does not match required format",
      "enter-new-email-address": "Enter new email address",
      "firstName-empty": "*First name is required",
      "invalid-email-address": "Invalid email adress",
      "languages-empty": "*Lang type is required",
      "lastName-empty": "*Last name is required",
      "mediaType-empty": "*Media type is required",
      "status-not-correct": "status is not correct",
      "territoryForOffice-empty": "*At least one territory location is required",
      "territoryOfficeName-empty": "*Territory office name is required"
    },
    "events": "Events",
    "feature.availableFilms": "Available Films",
    "feature.availablePictures": "Available Pictures",
    "feature.selectedPicture": "Selected Picture",
    "featuredFilms": "Featured Films",
    "female": "Female",
    "filmInformation": "Film Information",
    "firstName": "First Name",
    "formLabelAddTerritoryOfficeCountry": "Add a location to the territory office",
    "formLabelTerritoryOfficeName": "Territory Office Name",
    "frequently": "Frequently",
    "genre": "Genre",
    "hello world": "Hallo Welt",
    "how are you": "wie gehts {name}",
    "inactive": "Inactive",
    "info": "Information",
    "journalistApplication": "Journalist Application",
    "journalistGroup": "Journalist Group",
    "journalistInfo": "Journalist Information",
    "journalists": "Journalists",
    "language": "Language",
    "lastName": "Last Name",
    "lastUpdatedAt": "Last Updated At",
    "licenseExpireDate": "License Expire Date",
    "listing": "Listing",
    "loading": "loading",
    "localTitle": "Local Title",
    "localized": "Localized",
    "male": "Male",
    "management": "Journalists Control",
    "materials": "Materials",
    "materials.exclusives": "Exclusives",
    "materials.feature": "Feature",
    "materials.posters": "Posters",
    "mediaType": "Media Type",
    "move": "Move",
    "movies": "Movies",
    "moving": "Moving",
    "name": "Name",
    "new": "new",
    "newApplicant": "New Application",
    "newAssets": "New Assets",
    "newLocalTitle": "New Local Title",
    "newPassword": "New Password",
    "newRating": "New Rating",
    "newReleaseDate": "New Release Date",
    "newRunningTime": "New Running Time",
    "news": "News",
    "next": "Next",
    "next to materials to download": "neben Materialien zum Download",
    "nextTab": "Next Tab",
    "noData": "Sorry, we don't have anything for you",
    "noRecordFound": "No record can be found",
    "oldPassword": "Old Password",
    "operationSuccess": "Operation Success",
    "other": "Other",
    "postcode": "Postcode",
    "poster": "Poster",
    "posterConfirm": "Are you sure to select this poster?",
    "posters": "Posters",
    "pressRelease": "Press Release",
    "previousTab": "Previous Tab",
    "privacyPolicy": "Privacy Policy",
    "productId": "Product Id",
    "production": "Production",
    "productionCompany": "Production Company",
    "rated": "Rated",
    "rating": "Rating",
    "recordCreated": "Record Created",
    "recordUpdated": "Record Updated",
    "rejectApplicant": "Reject Application",
    "rejectedBy": "Rejected by",
    "rejectedComment": "Rejected Comment",
    "rejectedDate": "Rejected Date",
    "rejectedJournalist": "Rejected Journalist",
    "releaseDate": "Release Date",
    "retired": "Retired",
    "runningTime": "Running Time",
    "save": "Save",
    "saving": "Saving",
    "screenplay": "Screenplay",
    "select": "Select This",
    "selectCountryOfOrigin": "Please select country of origin",
    "selectLocation": "Please select a location first",
    "selectOption": "Please select an option first",
    "selected": "Selected",
    "selectedPoster": "Selected Poster",
    "send": "Send",
    "sending": "Sending",
    "signup.acknowledge18Warning": "Please acknowledge a8 years of age or above to proceed",
    "signup.acknowledgePPolicyWarning": "Please agree on the privacy policy to proceed",
    "signup.acknowledgeTNCWarning": "Please agree on the terms and conditions to proceed",
    "signup.chooseCountryWarning": "Please choose a copmany to proceed",
    "signup.companySelectedLabel": "The company that you selected",
    "signup.confirmSwitchToCreateCompany": "Are you sure to switch to create company mode?  Selected company will be erased",
    "signup.confirmSwitchToSearchCompany": "Are you sure to switch to search company?  All filled in fields will be erased",
    "signup.correctFormWarning": "Please correct the form error before proceed to next page",
    "signup.formButtonLabelCreateCompany": "Click here to create new company",
    "signup.formButtonLabelSearchCompany": "Click here to search for a company",
    "signup.formCompanyName": "Company Name",
    "signup.formLabelCompanyCountry": "Select the company country",
    "signup.formLabelEmail": "Email Adrress",
    "signup.formLabelFirstName": "First Name",
    "signup.formLabelLastCountryCitizenship": "Country Citizenship",
    "signup.formLabelLastName": "Last Name",
    "signup.formLabelSearchCompany": "Search Company",
    "signup.formLabelSearchCompanyTitle": "or enter company name to search and select it",
    "signup.formSearchLanguagesPlaceHolder": "Type to search languages...",
    "signup.formSearchNoCompanyFound": "No company found / Please try other search text",
    "signup.formSearching": "Searching",
    "signup.formTabCompanyInformation": "Company Information",
    "signup.formTabDemographic": "Demographic",
    "signup.formThanksyou": "Form submitted successfully, you will be contacted shortly about this application",
    "signup.header": "New User Registration",
    "signup.iAdknowledgeIm18": "I acknowledge that I'm 18 years of age or above",
    "signup.iAgreeToPolicy": "I agree to the privacy policy",
    "signup.iAgreeToTheTerms": "I agree to the terms and conditions",
    "signup.labelDemographic": "Demographic",
    "signup.languages": "Languages",
    "signup.loadingPolicy": "Loading Policy",
    "signup.loadingTerms": "Loading Terms",
    "signup.pageMenu1": "I'm not robot",
    "signup.pageMenu2": "Register New User",
    "signup.pageMenu3": "Choose Your Outlet",
    "signup.pageMenu4": "Terms and Conditions",
    "signup.pageMenu5": "Submitted",
    "signup.pageTitle": null,
    "signup.privacyPolicy": "Privacy Policy",
    "signup.selectCountryWarning": "Please select a country to proceed",
    "signup.selectedCompanyLabel": "Selected Company",
    "signup.verifyCaptchaWarning": "Please verify the captcha before proceed to next page",
    "specialShoots": "Special Shoots",
    "state": "State",
    "status": "Status",
    "submit": "Submit",
    "submitting": "Submitting",
    "summary": "Summary",
    "synopsis": "Synopsis",
    "termsAndConditions": "Terms and Conditions",
    "territoryOfficeName": "Territory Office Name",
    "title": "Title",
    "today": "Today",
    "useLocalized": "Use localized",
    "website": "Website",
    "writer": "Writer",
    "written": "Written"
  }], ["en-us", {
    "18YearsOrAbove": "18 years of age or above",
    "Add Event": "Add Event",
    "Assets": "Assets",
    "Calendar": "Calendar",
    "Coming Soon": "Coming Soon",
    "Content Management": "Content Management",
    "Downloads": "Downloads",
    "Edit Event": "Edit Event",
    "Embargo Agreement Date": "Embargo Agreement Date",
    "Embargo In Effect": "Embargo In Effect",
    "Embargo Release Date": "Embargo Release Date",
    "Embargo Start Date": "Embargo Start Date",
    "End Date": "End Date",
    "Event Location": "Event Location",
    "Event Name": "Event Name",
    "Event Type": "Event Type",
    "Events Management": "Events Management",
    "Film": "Film",
    "Global": "Global",
    "Headline": "Headline",
    "Instruction": "Instruction",
    "Journalists Management": "Journalists Management",
    "Local": "Local",
    "Marketing Automation": "Marketing Automation",
    "Messages": "Messages",
    "New": "New",
    "New Local Title": "New Local Title",
    "New Materials": "New Materials",
    "New Running Time": "New Running Time",
    "Our Country": "Our Country",
    "Please click on": "Please click on",
    "Release Dates": "Release Dates",
    "SAVE AMERICA": "SAVE AMERICA",
    "Select Language": "Select Language",
    "Share Screen": "Share Screen",
    "Start Date": "Start Date",
    "Territory": "Territory",
    "Timeline": "Timeline",
    "Topics": "Topics",
    "Upcoming Events": "Upcoming Events",
    "Upload": "Upload",
    "Upload Assets": "Upload Assets",
    "Users Management": "Users Management",
    "Video Conference": "Video Conference",
    "Welcome": "Welcome",
    "active": "Active",
    "activePoster": "Active Poster",
    "address": "Address",
    "adkSelectDropdown.selectAnOption": "Select an option",
    "agreeOn": "agree on",
    "app.admin": "Admin",
    "app.chinese": "Chinese",
    "app.embargo": "Embargo",
    "app.english": "English",
    "app.french": "French",
    "app.german": "German",
    "app.headquarters": "Headquarters",
    "app.home": "Home",
    "app.japanese": "Japanese",
    "app.journalistsWebsite": "Journalists Website",
    "app.korean": "Korean",
    "app.listOfMovies": "List of Movies",
    "app.timeline": "Timeline",
    "approvedBy": "Approved by",
    "approvedComment": "Approved Comment",
    "audioVisual": "Audio Visual",
    "availablePosters": "Available Posters",
    "back": "Back",
    "behindTheScenes": "Behind The Scenes",
    "cancel": "Cancel",
    "cancelConfirm": "Are you sure to cancel the changes (data will be reset)?",
    "check": "Check",
    "citizenOf": "Citizen Of",
    "city": "City",
    "close": "Close",
    "companyEmail": "Company Email",
    "companyInfo": "Company Information",
    "companyName": "Company Name",
    "confirmCancelOperation": "Are you sure to cancel this operation?",
    "confirmCreateTerritoryOffice": "Are you sure to create this territory office?",
    "confirmMoveOperation": "Are you sure to move this user?",
    "confirmPassword": "Confirm Password",
    "confirmRemoveAsset": "Are you sure to remove this asset?",
    "confirmSendOperation": "Are you sure to send this email?",
    "confirmUpdateTerritoryOffice": "Are you sure to update this territory office?",
    "contact": "Contact",
    "copyToLocal": "Copy to local",
    "country": "Country",
    "countryOfOrigin": "Country of Origin",
    "countryOfOriginSelect": "Please select country of origin",
    "credit": "Credit",
    "date": "Date",
    "dateApproved": "Date Approved",
    "dateAvailable": "Date Available",
    "dateCreated": "Date created",
    "dateExpires": "Date Expires",
    "dateSentIn": "Date sent in",
    "description": "Description",
    "directedBy": "Directed By",
    "director": "Director",
    "done": "Done",
    "downloadAll": "Download All",
    "draft": "Draft",
    "edit": "Edit",
    "email": "Email",
    "emailIsAllow": "Email is allowed",
    "errorDateBigger": "date expire should be greater than date available",
    "errorDateSmaller": "date expire should be smaller than date available",
    "errors": {
      "acknowledge-empty": "*You must agree with these agreement",
      "citizenship-empty": "*Citizenship is required",
      "companyAddress-empty": "*Address is required",
      "companyCity-empty": "*City is required",
      "companyName-empty": "*Company name is required",
      "companyState-empty": "*State is required",
      "country-empty": "*Country is required",
      "email-empty": "*Email address is required",
      "email-regex": "*Email does not match required format",
      "enter-new-email-address": "Enter new email address",
      "firstName-empty": "*First name is required",
      "invalid-email-address": "Invalid email adress",
      "languages-empty": "*Lang type is required",
      "lastName-empty": "*Last name is required",
      "mediaType-empty": "*Media type is required",
      "status-not-correct": "status is not correct",
      "territoryForOffice-empty": "*At least one territory location is required",
      "territoryOfficeName-empty": "*Territory office name is required"
    },
    "events": "Events",
    "feature.availableFilms": "Available Films",
    "feature.availablePictures": "Available Pictures",
    "feature.selectedPicture": "Selected Picture",
    "featuredFilms": "Featured Films",
    "female": "Female",
    "filmInformation": "Film Information",
    "firstName": "First Name",
    "formLabelAddTerritoryOfficeCountry": "Add a location to the territory office",
    "formLabelTerritoryOfficeName": "Territory Office Name",
    "frequently": "Frequently",
    "genre": "Genre",
    "inactive": "Inactive",
    "info": "Information",
    "journalistApplication": "Journalist Application",
    "journalistGroup": "Journalist Group",
    "journalistInfo": "Journalist Information",
    "journalists": "Journalists",
    "language": "Language",
    "lastName": "Last Name",
    "lastUpdatedAt": "Last Updated At",
    "licenseExpireDate": "License Expire Date",
    "listing": "Listing",
    "loading": "loading",
    "localTitle": "Local Title",
    "localized": "Localized",
    "male": "Male",
    "management": "Journalists Control",
    "materials": "Materials",
    "materials.exclusives": "Exclusives",
    "materials.feature": "Feature",
    "materials.posters": "Posters",
    "mediaType": "Media Type",
    "move": "Move",
    "movies": "Movies",
    "moving": "Moving",
    "name": "Name",
    "new": "new",
    "newApplicant": "New Application",
    "newAssets": "New Assets",
    "newLocalTitle": "New Local Title",
    "newPassword": "New Password",
    "newRating": "New Rating",
    "newReleaseDate": "New Release Date",
    "newRunningTime": "New Running Time",
    "news": "News",
    "next": "Next",
    "next to materials to download": "next to materials to download",
    "nextTab": "Next Tab",
    "noData": "Sorry, we don't have anything for you",
    "noRecordFound": "No record can be found",
    "oldPassword": "Old Password",
    "operationSuccess": "Operation Success",
    "other": "Other",
    "postcode": "Postcode",
    "poster": "Poster",
    "posterConfirm": "Are you sure to select this poster?",
    "posters": "Posters",
    "pressRelease": "Press Release",
    "previousTab": "Previous Tab",
    "privacyPolicy": "Privacy Policy",
    "productId": "Product Id",
    "production": "Production",
    "productionCompany": "Production Company",
    "rated": "Rated",
    "rating": "Rating",
    "recordCreated": "Record Created",
    "recordUpdated": "Record Updated",
    "rejectApplicant": "Reject Application",
    "rejectedBy": "Rejected by",
    "rejectedComment": "Rejected Comment",
    "rejectedDate": "Rejected Date",
    "rejectedJournalist": "Rejected Journalist",
    "releaseDate": "Release Date",
    "retired": "Retired",
    "runningTime": "Running Time",
    "save": "Save",
    "saving": "Saving",
    "screenplay": "Screenplay",
    "select": "Select This",
    "selectCountryOfOrigin": "Please select country of origin",
    "selectLocation": "Please select a location first",
    "selectOption": "Please select an option first",
    "selected": "Selected",
    "selectedPoster": "Selected Poster",
    "send": "Send",
    "sending": "Sending",
    "signup.acknowledge18Warning": "Please acknowledge a8 years of age or above to proceed",
    "signup.acknowledgePPolicyWarning": "Please agree on the privacy policy to proceed",
    "signup.acknowledgeTNCWarning": "Please agree on the terms and conditions to proceed",
    "signup.chooseCountryWarning": "Please choose a copmany to proceed",
    "signup.companySelectedLabel": "The company that you selected",
    "signup.confirmSwitchToCreateCompany": "Are you sure to switch to create company mode?  Selected company will be erased",
    "signup.confirmSwitchToSearchCompany": "Are you sure to switch to search company?  All filled in fields will be erased",
    "signup.correctFormWarning": "Please correct the form error before proceed to next page",
    "signup.formButtonLabelCreateCompany": "Click here to create new company",
    "signup.formButtonLabelSearchCompany": "Click here to search for a company",
    "signup.formCompanyName": "Company Name",
    "signup.formLabelCompanyCountry": "Select the company country",
    "signup.formLabelEmail": "Email Adrress",
    "signup.formLabelFirstName": "First Name",
    "signup.formLabelLastCountryCitizenship": "Country Citizenship",
    "signup.formLabelLastName": "Last Name",
    "signup.formLabelSearchCompany": "Search Company",
    "signup.formLabelSearchCompanyTitle": "or enter company name to search and select it",
    "signup.formSearchLanguagesPlaceHolder": "Type to search languages...",
    "signup.formSearchNoCompanyFound": "No company found / Please try other search text",
    "signup.formSearching": "Searching",
    "signup.formTabCompanyInformation": "Company Information",
    "signup.formTabDemographic": "Demographic",
    "signup.formThanksyou": "Form submitted successfully, you will be contacted shortly about this application",
    "signup.header": "New User Registration",
    "signup.iAdknowledgeIm18": "I acknowledge that I'm 18 years of age or above",
    "signup.iAgreeToPolicy": "I agree to the privacy policy",
    "signup.iAgreeToTheTerms": "I agree to the terms and conditions",
    "signup.labelDemographic": "Demographic",
    "signup.languages": "Languages",
    "signup.loadingPolicy": "Loading Policy",
    "signup.loadingTerms": "Loading Terms",
    "signup.pageMenu1": "I'm not robot",
    "signup.pageMenu2": "Register New User",
    "signup.pageMenu3": "Choose Your Outlet",
    "signup.pageMenu4": "Terms and Conditions",
    "signup.pageMenu5": "Submitted",
    "signup.pageTitle": null,
    "signup.privacyPolicy": "Privacy Policy",
    "signup.selectCountryWarning": "Please select a country to proceed",
    "signup.selectedCompanyLabel": "Selected Company",
    "signup.verifyCaptchaWarning": "Please verify the captcha before proceed to next page",
    "specialShoots": "Special Shoots",
    "state": "State",
    "status": "Status",
    "submit": "Submit",
    "submitting": "Submitting",
    "summary": "Summary",
    "synopsis": "Synopsis",
    "termsAndConditions": "Terms and Conditions",
    "territoryOfficeName": "Territory Office Name",
    "title": "Title",
    "today": "Today",
    "useLocalized": "Use localized",
    "website": "Website",
    "writer": "Writer",
    "written": "Written"
  }], ["fr-fr", {
    "18YearsOrAbove": "18 years of age or above",
    "Active": "Active",
    "Add Event": "Ajouter un évènement",
    "Admin": "Administrateur",
    "Assets": "Les atouts",
    "Audio Visual": "Audiovisuel",
    "Available Films": "Films disponibles",
    "Available Pictures": "Images disponibles",
    "Available Posters": "Affiches disponibles",
    "Behind The Scenes": "Dans les coulisses",
    "Calendar": "Calendrier",
    "Chinese": "Chinois",
    "Coming Soon": "À venir",
    "Content Management": "Gestion de contenu",
    "Credit": "honneur",
    "Date": "Date",
    "Date Available": "Date de disponibilité",
    "Date Expires": "Date d'expiration",
    "Description": "La description",
    "Directed By": "Réalisé par",
    "Download All": "Tout télécharger",
    "Downloads": "Téléchargements",
    "Edit Event": "Modifier l'événement",
    "Embargo": "Embargo",
    "Embargo Agreement Date": "EDate de l'accord d'embargo",
    "Embargo In Effect": "Embargo en vigueur",
    "Embargo Release Date": "Date de sortie de l'embargo",
    "Embargo Start Date": "Date de début de l'embargo",
    "End Date": "Date de fin",
    "English": "English",
    "Event Location": "Lieu de l'événement",
    "Event Name": "Nom de l'événement",
    "Event Type": "Type d'événement",
    "Events": "Événements",
    "Events Management": "Gestion d'événements",
    "Exclusives": "Exclusivités",
    "Featured Film": "Film en vedette",
    "Featured Films": "Films en vedette",
    "Film": "Film",
    "Film Information": "Informations sur les films",
    "French": "Français",
    "German": "Allemand",
    "Global": "Globale",
    "Headline": "Gros titre",
    "Headquarters": "Quartier général",
    "Home": "Page d'accueil",
    "Inactive": "Inactive",
    "Instruction": "Instruction",
    "Japanese": "Japonais",
    "Journalists Management": "Gestion des journalistes",
    "Journalists Website": "Site Web des journalistes",
    "Last Updated At": "Dernière mise à jour à",
    "List of Movies": "Liste des films",
    "Local": "Locale",
    "Local Title": "Titre local",
    "Localized": "Localisée",
    "Marketing Automation": "Automatisation du marketing",
    "Materials": "Matériaux",
    "Messages": "Messages",
    "New": "Nouvelle",
    "New Assets": "Nouveaux actifs",
    "New Local Title": "Nouveau titre local",
    "New Materials": "Nouveaux matériaux",
    "New Release Date": "Nouvelle date de sortie",
    "New Running Time": "New Running Time",
    "News": "Nouvelles",
    "Our Country": "Notre pays",
    "Please click on": "Veuillez cliquer sur",
    "Posters": "Affiches",
    "Privacy Policy": "Politique de confidentialité",
    "Production": "Production",
    "Rated": "Évalué",
    "Release Date": "Date de sortie",
    "Release Dates": "Date de sortie",
    "SAVE AMERICA": "SAUVEZ L'AMÉRIQUE",
    "Select Language": "Choisir la langue",
    "Selected Picture": "Image sélectionnée",
    "Selected Poster": "Affiche sélectionnée",
    "Share Screen": "Partager l'écran",
    "Special Shoots": "Tirs spéciaux",
    "Start Date": "Date de début",
    "Status": "Statut",
    "Summary": "Sommaire",
    "Synopsis": "Synopsis",
    "Terms and Conditions": "Termes et conditions",
    "Territory": "Territory",
    "Timeline": "Chronologie",
    "Title": "Titre",
    "Topics": "Les sujets",
    "Upcoming Events": "évènements à venir",
    "Upload": "Télécharger",
    "Upload Assets": "Télécharger des éléments",
    "Use localized": "Utiliser localisé",
    "Users Management": "Gestion des utilisateurs",
    "Video Conference": "Conférence vidéo",
    "Welcome": "Bienvenue",
    "Writer": "Écrivain",
    "Written": "Écrit",
    "active": "Active",
    "activePoster": "Active Poster",
    "address": "Address",
    "adkSelectDropdown.selectAnOption": "Select an option",
    "agreeOn": "agree on",
    "app.admin": "Admin",
    "app.chinese": "Chinese",
    "app.embargo": "Embargo",
    "app.english": "English",
    "app.french": "French",
    "app.german": "German",
    "app.headquarters": "Headquarters",
    "app.home": "Home",
    "app.japanese": "Japanese",
    "app.journalistsWebsite": "Journalists Website",
    "app.korean": "Korean",
    "app.listOfMovies": "List of Movies",
    "app.timeline": "Timeline",
    "approvedBy": "Approved by",
    "approvedComment": "Approved Comment",
    "audioVisual": "Audio Visual",
    "availablePosters": "Available Posters",
    "back": "Back",
    "behindTheScenes": "Behind The Scenes",
    "cancel": "Cancel",
    "cancelConfirm": "Are you sure to cancel the changes (data will be reset)?",
    "check": "Check",
    "citizenOf": "Citizen Of",
    "city": "City",
    "close": "Close",
    "companyEmail": "Company Email",
    "companyInfo": "Company Information",
    "companyName": "Company Name",
    "confirmCancelOperation": "Are you sure to cancel this operation?",
    "confirmCreateTerritoryOffice": "Are you sure to create this territory office?",
    "confirmMoveOperation": "Are you sure to move this user?",
    "confirmPassword": "Confirm Password",
    "confirmRemoveAsset": "Are you sure to remove this asset?",
    "confirmSendOperation": "Are you sure to send this email?",
    "confirmUpdateTerritoryOffice": "Are you sure to update this territory office?",
    "contact": "Contact",
    "copyToLocal": "Copy to local",
    "country": "Country",
    "countryOfOrigin": "Country of Origin",
    "countryOfOriginSelect": "Please select country of origin",
    "credit": "Credit",
    "date": "Date",
    "dateApproved": "Date Approved",
    "dateAvailable": "Date Available",
    "dateCreated": "Date created",
    "dateExpires": "Date Expires",
    "dateSentIn": "Date sent in",
    "description": "Description",
    "directedBy": "Directed By",
    "director": "Director",
    "done": "Done",
    "downloadAll": "Download All",
    "draft": "Draft",
    "edit": "Edit",
    "email": "Email",
    "emailIsAllow": "Email is allowed",
    "errorDateBigger": "date expire should be greater than date available",
    "errorDateSmaller": "date expire should be smaller than date available",
    "errors": {
      "acknowledge-empty": "*You must agree with these agreement",
      "citizenship-empty": "*Citizenship is required",
      "companyAddress-empty": "*Address is required",
      "companyCity-empty": "*City is required",
      "companyName-empty": "*Company name is required",
      "companyState-empty": "*State is required",
      "country-empty": "*Country is required",
      "email-empty": "*Email address is required",
      "email-regex": "*Email does not match required format",
      "enter-new-email-address": "Enter new email address",
      "firstName-empty": "*First name is required",
      "invalid-email-address": "Invalid email adress",
      "languages-empty": "*Lang type is required",
      "lastName-empty": "*Last name is required",
      "mediaType-empty": "*Media type is required",
      "status-not-correct": "status is not correct",
      "territoryForOffice-empty": "*At least one territory location is required",
      "territoryOfficeName-empty": "*Territory office name is required"
    },
    "events": "Events",
    "feature.availableFilms": "Available Films",
    "feature.availablePictures": "Available Pictures",
    "feature.selectedPicture": "Selected Picture",
    "featuredFilms": "Featured Films",
    "female": "Female",
    "filmInformation": "Film Information",
    "firstName": "First Name",
    "formLabelAddTerritoryOfficeCountry": "Add a location to the territory office",
    "formLabelTerritoryOfficeName": "Territory Office Name",
    "frequently": "Frequently",
    "genre": "Genre",
    "hello world": "Bonjour le monde",
    "how are you": "Comment allez-vous {name}",
    "inactive": "Inactive",
    "info": "Information",
    "journalistApplication": "Journalist Application",
    "journalistGroup": "Journalist Group",
    "journalistInfo": "Journalist Information",
    "journalists": "Journalists",
    "language": "Language",
    "lastName": "Last Name",
    "lastUpdatedAt": "Last Updated At",
    "licenseExpireDate": "License Expire Date",
    "listing": "Listing",
    "loading": "loading",
    "localTitle": "Local Title",
    "localized": "Localized",
    "male": "Male",
    "management": "Journalists Control",
    "materials": "Materials",
    "materials.exclusives": "Exclusives",
    "materials.feature": "Feature",
    "materials.posters": "Posters",
    "mediaType": "Media Type",
    "move": "Move",
    "movies": "Movies",
    "moving": "Moving",
    "name": "Name",
    "new": "new",
    "newApplicant": "New Application",
    "newAssets": "New Assets",
    "newLocalTitle": "New Local Title",
    "newPassword": "New Password",
    "newRating": "New Rating",
    "newReleaseDate": "New Release Date",
    "newRunningTime": "New Running Time",
    "news": "News",
    "next": "Next",
    "next to materials to download": "à côté des documents à télécharger",
    "nextTab": "Next Tab",
    "noData": "Sorry, we don't have anything for you",
    "noRecordFound": "No record can be found",
    "oldPassword": "Old Password",
    "operationSuccess": "Operation Success",
    "other": "Other",
    "postcode": "Postcode",
    "poster": "Poster",
    "posterConfirm": "Are you sure to select this poster?",
    "posters": "Posters",
    "pressRelease": "Press Release",
    "previousTab": "Previous Tab",
    "privacyPolicy": "Privacy Policy",
    "productId": "Product Id",
    "production": "Production",
    "productionCompany": "Production Company",
    "rated": "Rated",
    "rating": "Rating",
    "recordCreated": "Record Created",
    "recordUpdated": "Record Updated",
    "rejectApplicant": "Reject Application",
    "rejectedBy": "Rejected by",
    "rejectedComment": "Rejected Comment",
    "rejectedDate": "Rejected Date",
    "rejectedJournalist": "Rejected Journalist",
    "releaseDate": "Release Date",
    "retired": "Retired",
    "runningTime": "Running Time",
    "save": "Save",
    "saving": "Saving",
    "screenplay": "Screenplay",
    "select": "Select This",
    "selectCountryOfOrigin": "Please select country of origin",
    "selectLocation": "Please select a location first",
    "selectOption": "Please select an option first",
    "selected": "Selected",
    "selectedPoster": "Selected Poster",
    "send": "Send",
    "sending": "Sending",
    "signup.acknowledge18Warning": "Please acknowledge a8 years of age or above to proceed",
    "signup.acknowledgePPolicyWarning": "Please agree on the privacy policy to proceed",
    "signup.acknowledgeTNCWarning": "Please agree on the terms and conditions to proceed",
    "signup.chooseCountryWarning": "Please choose a copmany to proceed",
    "signup.companySelectedLabel": "The company that you selected",
    "signup.confirmSwitchToCreateCompany": "Are you sure to switch to create company mode?  Selected company will be erased",
    "signup.confirmSwitchToSearchCompany": "Are you sure to switch to search company?  All filled in fields will be erased",
    "signup.correctFormWarning": "Please correct the form error before proceed to next page",
    "signup.formButtonLabelCreateCompany": "Click here to create new company",
    "signup.formButtonLabelSearchCompany": "Click here to search for a company",
    "signup.formCompanyName": "Company Name",
    "signup.formLabelCompanyCountry": "Select the company country",
    "signup.formLabelEmail": "Email Adrress",
    "signup.formLabelFirstName": "First Name",
    "signup.formLabelLastCountryCitizenship": "Country Citizenship",
    "signup.formLabelLastName": "Last Name",
    "signup.formLabelSearchCompany": "Search Company",
    "signup.formLabelSearchCompanyTitle": "or enter company name to search and select it",
    "signup.formSearchLanguagesPlaceHolder": "Type to search languages...",
    "signup.formSearchNoCompanyFound": "No company found / Please try other search text",
    "signup.formSearching": "Searching",
    "signup.formTabCompanyInformation": "Company Information",
    "signup.formTabDemographic": "Demographic",
    "signup.formThanksyou": "Form submitted successfully, you will be contacted shortly about this application",
    "signup.header": "New User Registration",
    "signup.iAdknowledgeIm18": "I acknowledge that I'm 18 years of age or above",
    "signup.iAgreeToPolicy": "I agree to the privacy policy",
    "signup.iAgreeToTheTerms": "I agree to the terms and conditions",
    "signup.labelDemographic": "Demographic",
    "signup.languages": "Languages",
    "signup.loadingPolicy": "Loading Policy",
    "signup.loadingTerms": "Loading Terms",
    "signup.pageMenu1": "I'm not robot",
    "signup.pageMenu2": "Register New User",
    "signup.pageMenu3": "Choose Your Outlet",
    "signup.pageMenu4": "Terms and Conditions",
    "signup.pageMenu5": "Submitted",
    "signup.pageTitle": null,
    "signup.privacyPolicy": "Privacy Policy",
    "signup.selectCountryWarning": "Please select a country to proceed",
    "signup.selectedCompanyLabel": "Selected Company",
    "signup.verifyCaptchaWarning": "Please verify the captcha before proceed to next page",
    "specialShoots": "Special Shoots",
    "state": "State",
    "status": "Status",
    "submit": "Submit",
    "submitting": "Submitting",
    "summary": "Summary",
    "synopsis": "Synopsis",
    "termsAndConditions": "Terms and Conditions",
    "territoryOfficeName": "Territory Office Name",
    "title": "Title",
    "today": "Today",
    "useLocalized": "Use localized",
    "website": "Website",
    "writer": "Writer",
    "written": "Written"
  }], ["ja", {
    "18YearsOrAbove": "18 years of age or above",
    "Add Event": "イベント追加",
    "Assets": "資産",
    "Available Posters": "ポスター",
    "Calendar": "カレンダー",
    "Coming Soon": "近日公開予定",
    "Content Management": "コンテンツ管理",
    "Credit": "クレジット",
    "Date Available": "利用可能日",
    "Date Expires": "有効期限",
    "Description": "内容",
    "Directed By": "監督",
    "Done": "完了",
    "Download All": "すべてダウンロード",
    "Downloads": "ダウンロード",
    "Edit Event": "イベント編集",
    "Embargo Agreement Date": "エンバーゴ協定締結日",
    "Embargo In Effect": "エンバーゴ・イン・エフェクト",
    "Embargo Release Date": "禁輸解除日",
    "Embargo Start Date": "禁止事項の開始日",
    "End Date": "終了日",
    "Event Location": "イベント開催場所",
    "Event Name": "イベント名",
    "Event Type": "イベントの種類",
    "Events Management": "イベント管理",
    "Film": "映画",
    "Global": "Global",
    "Headline": "Headline",
    "Instruction": "指導内容",
    "Journalists Management": "ジャーナリストマネジメント",
    "Local": "Local",
    "Local Title": "ローカルタイトル",
    "Localized": "ローカライズ",
    "Marketing Automation": "マーケティングオートメーション",
    "Messages": "メッセージ",
    "New": "New",
    "New Local Title": "新しいローカルタイトル",
    "New Materials": "新着資料",
    "New Release Date": "新発売日",
    "New Running Time": "New Running Time",
    "Our Country": "私たちの国",
    "Please click on": "をクリックしてください。",
    "Posters": "ポスター",
    "Rated": "定格",
    "Release Date": "発売日",
    "Release Dates": "発売日",
    "SAVE AMERICA": "セーブ・アメリカ",
    "Select Language": "言語選択",
    "Selected Poster": "選択したポスター",
    "Share Screen": "共有画面",
    "Start Date": "開始日",
    "Summary": "概要",
    "Synopsis": "あらすじ",
    "Territory": "Territory",
    "Timeline": "Timeline",
    "Title": "作品名",
    "Topics": "トピックス",
    "Upcoming Events": "イベント情報",
    "Upload": "アップロード",
    "Upload Assets": "アセットをアップロードする",
    "Use localized": "ローカライズされたものを使用",
    "Users Management": "ユーザー管理",
    "Video Conference": "ビデオ会議",
    "Welcome": "ようこそ",
    "Writer": "脚本家",
    "active": "Active",
    "activePoster": "Active Poster",
    "address": "Address",
    "adkSelectDropdown.selectAnOption": "Select an option",
    "agreeOn": "agree on",
    "app.admin": "管理者",
    "app.chinese": "中国語",
    "app.embargo": "エンバーゴ",
    "app.english": "English",
    "app.french": "フランス語",
    "app.german": "ドイツ語",
    "app.headquarters": "本社",
    "app.home": "ホーム",
    "app.japanese": "日本語",
    "app.journalistsWebsite": "ジャーナリストサイト",
    "app.korean": "韓国語",
    "app.listOfMovies": "作品リスト",
    "app.timeline": "年表",
    "approvedBy": "Approved by",
    "approvedComment": "Approved Comment",
    "audioVisual": "オーディオビジュアル",
    "availablePosters": "Available Posters",
    "back": "Back",
    "behindTheScenes": "撮影の裏側",
    "cancel": "Cancel",
    "cancelConfirm": "Are you sure to cancel the changes (data will be reset)?",
    "check": "Check",
    "citizenOf": "Citizen Of",
    "city": "City",
    "close": "Close",
    "companyEmail": "Company Email",
    "companyInfo": "Company Information",
    "companyName": "Company Name",
    "confirmCancelOperation": "Are you sure to cancel this operation?",
    "confirmCreateTerritoryOffice": "Are you sure to create this territory office?",
    "confirmMoveOperation": "Are you sure to move this user?",
    "confirmPassword": "Confirm Password",
    "confirmRemoveAsset": "Are you sure to remove this asset?",
    "confirmSendOperation": "Are you sure to send this email?",
    "confirmUpdateTerritoryOffice": "Are you sure to update this territory office?",
    "contact": "Contact",
    "copyToLocal": "Copy to local",
    "country": "Country",
    "countryOfOrigin": "Country of Origin",
    "countryOfOriginSelect": "Please select country of origin",
    "credit": "Credit",
    "date": "Date",
    "dateApproved": "Date Approved",
    "dateAvailable": "Date Available",
    "dateCreated": "Date created",
    "dateExpires": "Date Expires",
    "dateSentIn": "Date sent in",
    "description": "Description",
    "directedBy": "Directed By",
    "director": "Director",
    "done": "Done",
    "downloadAll": "Download All",
    "draft": "Draft",
    "edit": "Edit",
    "email": "Email",
    "emailIsAllow": "Email is allowed",
    "errorDateBigger": "date expire should be greater than date available",
    "errorDateSmaller": "date expire should be smaller than date available",
    "errors": {
      "acknowledge-empty": "*本規約に同意していただく必要があります",
      "citizenship-empty": "*市民権が必要です",
      "companyAddress-empty": "*Address is required",
      "companyCity-empty": "*City is required",
      "companyName-empty": "*Company name is required",
      "companyState-empty": "*State is required",
      "country-empty": "*国名が必要です",
      "email-empty": "*メールアドレスが必要です",
      "email-regex": "*Email does not match required format",
      "enter-new-email-address": "Enter new email address",
      "firstName-empty": "*ファーストネームは必須です",
      "invalid-email-address": "Invalid email adress",
      "languages-empty": "*Lang type is required",
      "lastName-empty": "*姓が必要です",
      "mediaType-empty": "*メディアタイプは必須です",
      "status-not-correct": "status is not correct",
      "territoryForOffice-empty": "*At least one territory location is required",
      "territoryOfficeName-empty": "*Territory office name is required"
    },
    "events": "イベント情報",
    "feature.availableFilms": "上映作品",
    "feature.availablePictures": "写真",
    "feature.selectedPicture": "選択した画像",
    "featuredFilms": "長編映画",
    "female": "Female",
    "filmInformation": "映画情報",
    "firstName": "First Name",
    "formLabelAddTerritoryOfficeCountry": "Add a location to the territory office",
    "formLabelTerritoryOfficeName": "Territory Office Name",
    "frequently": "Frequently",
    "genre": "Genre",
    "inactive": "Inactive",
    "info": "Information",
    "journalistApplication": "Journalist Application",
    "journalistGroup": "Journalist Group",
    "journalistInfo": "Journalist Information",
    "journalists": "Journalists",
    "language": "Language",
    "lastName": "Last Name",
    "lastUpdatedAt": "Last Updated At",
    "licenseExpireDate": "License Expire Date",
    "listing": "Listing",
    "loading": "loading",
    "localTitle": "Local Title",
    "localized": "Localized",
    "male": "Male",
    "management": "Journalists Control",
    "materials": "資料紹介",
    "materials.exclusives": "独占販売",
    "materials.feature": "特集",
    "materials.posters": "ポスター",
    "mediaType": "Media Type",
    "move": "Move",
    "movies": "Movies",
    "moving": "Moving",
    "name": "Name",
    "new": "new",
    "newApplicant": "New Application",
    "newAssets": "新着アセット",
    "newLocalTitle": "New Local Title",
    "newPassword": "New Password",
    "newRating": "New Rating",
    "newReleaseDate": "New Release Date",
    "newRunningTime": "New Running Time",
    "news": "ニュース",
    "next": "Next",
    "next to materials to download": "ダウンロードするには、資料の横にある",
    "nextTab": "Next Tab",
    "noData": "Sorry, we don't have anything for you",
    "noRecordFound": "No record can be found",
    "oldPassword": "Old Password",
    "operationSuccess": "Operation Success",
    "other": "Other",
    "postcode": "Postcode",
    "poster": "ポスター",
    "posterConfirm": "Are you sure to select this poster?",
    "posters": "Posters",
    "pressRelease": "Press Release",
    "previousTab": "Previous Tab",
    "privacyPolicy": "個人情報保護方針",
    "productId": "Product Id",
    "production": "生産",
    "productionCompany": "Production Company",
    "rated": "Rated",
    "rating": "Rating",
    "recordCreated": "Record Created",
    "recordUpdated": "Record Updated",
    "rejectApplicant": "Reject Application",
    "rejectedBy": "Rejected by",
    "rejectedComment": "Rejected Comment",
    "rejectedDate": "Rejected Date",
    "rejectedJournalist": "Rejected Journalist",
    "releaseDate": "Release Date",
    "retired": "Retired",
    "runningTime": "Running Time",
    "save": "Save",
    "saving": "Saving",
    "screenplay": "Screenplay",
    "select": "Select This",
    "selectCountryOfOrigin": "Please select country of origin",
    "selectLocation": "Please select a location first",
    "selectOption": "Please select an option first",
    "selected": "Selected",
    "selectedPoster": "Selected Poster",
    "send": "Send",
    "sending": "Sending",
    "signup.acknowledge18Warning": "Please acknowledge a8 years of age or above to proceed",
    "signup.acknowledgePPolicyWarning": "Please agree on the privacy policy to proceed",
    "signup.acknowledgeTNCWarning": "Please agree on the terms and conditions to proceed",
    "signup.chooseCountryWarning": "Please choose a copmany to proceed",
    "signup.companySelectedLabel": "The company that you selected",
    "signup.confirmSwitchToCreateCompany": "Are you sure to switch to create company mode?  Selected company will be erased",
    "signup.confirmSwitchToSearchCompany": "Are you sure to switch to search company?  All filled in fields will be erased",
    "signup.correctFormWarning": "Please correct the form error before proceed to next page",
    "signup.formButtonLabelCreateCompany": "Click here to create new company",
    "signup.formButtonLabelSearchCompany": "Click here to search for a company",
    "signup.formCompanyName": "Company Name",
    "signup.formLabelCompanyCountry": "Select the company country",
    "signup.formLabelEmail": "Email Adrress",
    "signup.formLabelFirstName": "First Name",
    "signup.formLabelLastCountryCitizenship": "Country Citizenship",
    "signup.formLabelLastName": "Last Name",
    "signup.formLabelSearchCompany": "Search Company",
    "signup.formLabelSearchCompanyTitle": "or enter company name to search and select it",
    "signup.formSearchLanguagesPlaceHolder": "Type to search languages...",
    "signup.formSearchNoCompanyFound": "No company found / Please try other search text",
    "signup.formSearching": "Searching",
    "signup.formTabCompanyInformation": "Company Information",
    "signup.formTabDemographic": "Demographic",
    "signup.formThanksyou": "Form submitted successfully, you will be contacted shortly about this application",
    "signup.header": "New User Registration",
    "signup.iAdknowledgeIm18": "I acknowledge that I'm 18 years of age or above",
    "signup.iAgreeToPolicy": "I agree to the privacy policy",
    "signup.iAgreeToTheTerms": "I agree to the terms and conditions",
    "signup.labelDemographic": "Demographic",
    "signup.languages": "Languages",
    "signup.loadingPolicy": "Loading Policy",
    "signup.loadingTerms": "Loading Terms",
    "signup.pageMenu1": "I'm not robot",
    "signup.pageMenu2": "Register New User",
    "signup.pageMenu3": "Choose Your Outlet",
    "signup.pageMenu4": "Terms and Conditions",
    "signup.pageMenu5": "Submitted",
    "signup.pageTitle": null,
    "signup.privacyPolicy": "Privacy Policy",
    "signup.selectCountryWarning": "Please select a country to proceed",
    "signup.selectedCompanyLabel": "Selected Company",
    "signup.verifyCaptchaWarning": "Please verify the captcha before proceed to next page",
    "specialShoots": "特別撮影",
    "state": "State",
    "status": "Status",
    "submit": "Submit",
    "submitting": "Submitting",
    "summary": "Summary",
    "synopsis": "Synopsis",
    "termsAndConditions": "ご利用条件",
    "territoryOfficeName": "Territory Office Name",
    "title": "Title",
    "today": "Today",
    "useLocalized": "Use localized",
    "website": "Website",
    "writer": "Writer",
    "written": "執筆"
  }], ["ko-kr", {
    "18YearsOrAbove": "18 years of age or above",
    "Active": "활동적인",
    "Add Event": "이벤트 수정",
    "Assets": "자산",
    "Calendar": "달력",
    "Coming Soon": "출시 예정",
    "Content Management": "콘텐츠 관리",
    "Country of Origin": "원산지",
    "Credit": "업로드",
    "Date": "날짜",
    "Date Available": "만료 날짜",
    "Date Expires": "신용 거래",
    "Directed By": "감독",
    "Director": "감독",
    "Done": "완료",
    "Download All": "모두 다운로드",
    "Downloads": "다운로드",
    "Draft": "초안",
    "Edit Event": "현지화",
    "Embargo Agreement Date": "엠바고 계약 날짜",
    "Embargo In Effect": "엠바고 발효 중",
    "Embargo Release Date": "엠바고 출시일",
    "Embargo Start Date": "엠바고 시작일",
    "End Date": "종료일",
    "Event Location": "이벤트 유형",
    "Event Name": "이벤트 추가",
    "Event Type": "이벤트 이름",
    "Events Management": "이벤트 관리",
    "Film": "영화",
    "Genre": "장르",
    "Global": "글로벌",
    "Headline": "표제",
    "Inactive": "비활성",
    "Instruction": "지침",
    "Journalists Management": "새 지역 제목",
    "Language": "언어",
    "Last Updated At": "마지막 업데이트 날짜",
    "License Expire Date": "라이센스 만료 날짜",
    "Local": "현지의",
    "Local Title": "현지화 사용",
    "Localized": "기자 관리",
    "Marketing Automation": "마케팅 자동화",
    "Messages": "메시지",
    "Movies": "영화 산업",
    "New": "새로운",
    "New Local Title": "새로운 출시일",
    "New Materials": "신소재",
    "New Rating": "새로운 평가",
    "New Release Date": "지역명",
    "New Running Time": "새로운 실행 시간",
    "Our Country": "우리 나라",
    "Please click on": "클릭하십시오",
    "Posters": "포스터",
    "Product Id": "제품 ID",
    "Production Company": "제조업 회사",
    "Rated": "정격",
    "Rating": "평가",
    "Release Date": "출시일",
    "Release Dates": "출시일",
    "Retired": "은퇴",
    "Running Time": "시간을 실행",
    "SAVE AMERICA": "미국을 구하다",
    "Screenplay": "각본",
    "Select Language": "언어 선택",
    "Selected Poster": "사용 가능한 사진",
    "Share Screen": "화면 공유",
    "Start Date": "시작일",
    "Status": "상태",
    "Summary": "요약",
    "Synopsis": "개요",
    "Territory": "영토 사무소",
    "Timeline": "타임라인",
    "Title": "제목",
    "Topics": "주제",
    "Upcoming Events": "다가오는 이벤트",
    "Upload": "자산 업로드",
    "Upload Assets": "사용 가능한 포스터",
    "Use localized": "가능한 날짜",
    "Users Management": "사용자 관리",
    "Video Conference": "화상 회의",
    "Welcome": "어서 오십시오",
    "Writer": "작가",
    "active": "Active",
    "activePoster": "활성 포스터",
    "address": "Address",
    "adkSelectDropdown.selectAnOption": "Select an option",
    "agreeOn": "agree on",
    "app.admin": "관리자",
    "app.chinese": "중국인",
    "app.embargo": "금지",
    "app.english": "English",
    "app.french": "프랑스 국민",
    "app.german": "독일 사람",
    "app.headquarters": "본부",
    "app.home": "집",
    "app.japanese": "일본어",
    "app.journalistsWebsite": "기자 웹사이트",
    "app.korean": "한국어",
    "app.listOfMovies": "영화 목록",
    "app.timeline": "타임라인",
    "approvedBy": "Approved by",
    "approvedComment": "Approved Comment",
    "audioVisual": "시청각",
    "availablePosters": "선정된 포스터",
    "back": "Back",
    "behindTheScenes": "무대 뒤에서",
    "cancel": "취소",
    "cancelConfirm": "Are you sure to cancel the changes (data will be reset)?",
    "check": "Check",
    "citizenOf": "Citizen Of",
    "city": "City",
    "close": "Close",
    "companyEmail": "Company Email",
    "companyInfo": "Company Information",
    "companyName": "Company Name",
    "confirmCancelOperation": "Are you sure to cancel this operation?",
    "confirmCreateTerritoryOffice": "Are you sure to create this territory office?",
    "confirmMoveOperation": "Are you sure to move this user?",
    "confirmPassword": "Confirm Password",
    "confirmRemoveAsset": "Are you sure to remove this asset?",
    "confirmSendOperation": "Are you sure to send this email?",
    "confirmUpdateTerritoryOffice": "Are you sure to update this territory office?",
    "contact": "Contact",
    "copyToLocal": "Copy to local",
    "country": "Country",
    "countryOfOrigin": "Country of Origin",
    "countryOfOriginSelect": "Please select country of origin",
    "credit": "Credit",
    "date": "Date",
    "dateApproved": "Date Approved",
    "dateAvailable": "Date Available",
    "dateCreated": "Date created",
    "dateExpires": "Date Expires",
    "dateSentIn": "Date sent in",
    "description": "설명",
    "directedBy": "Directed By",
    "director": "Director",
    "done": "Done",
    "downloadAll": "Download All",
    "draft": "Draft",
    "edit": "편집하다",
    "email": "Email",
    "emailIsAllow": "Email is allowed",
    "errorDateBigger": "date expire should be greater than date available",
    "errorDateSmaller": "date expire should be smaller than date available",
    "errors": {
      "acknowledge-empty": "*이 계약에 동의해야 합니다",
      "citizenship-empty": "*시민권이 필요합니다",
      "companyAddress-empty": "*Address is required",
      "companyCity-empty": "*City is required",
      "companyName-empty": "*Company name is required",
      "companyState-empty": "*State is required",
      "country-empty": "*국가는 필수 항목입니다.",
      "email-empty": "*이메일 주소는 필수 항목입니다.",
      "email-regex": "*Email does not match required format",
      "enter-new-email-address": "Enter new email address",
      "firstName-empty": "*이름은 필수 항목입니다.",
      "invalid-email-address": "Invalid email adress",
      "languages-empty": "*Lang type is required",
      "lastName-empty": "*이름은 필수 항목입니다.",
      "mediaType-empty": "*미디어 유형은 필수 항목입니다.",
      "status-not-correct": "status is not correct",
      "territoryForOffice-empty": "*At least one territory location is required",
      "territoryOfficeName-empty": "*Territory office name is required"
    },
    "events": "이벤트",
    "feature.availableFilms": "사용 가능한 필름",
    "feature.availablePictures": "사용 가능한 사진",
    "feature.selectedPicture": "선택한 사진",
    "featuredFilms": "추천 영화",
    "female": "Female",
    "filmInformation": "영화정보",
    "firstName": "First Name",
    "formLabelAddTerritoryOfficeCountry": "Add a location to the territory office",
    "formLabelTerritoryOfficeName": "Territory Office Name",
    "frequently": "Frequently",
    "genre": "Genre",
    "inactive": "Inactive",
    "info": "Information",
    "journalistApplication": "Journalist Application",
    "journalistGroup": "Journalist Group",
    "journalistInfo": "Journalist Information",
    "journalists": "Journalists",
    "language": "Language",
    "lastName": "Last Name",
    "lastUpdatedAt": "Last Updated At",
    "licenseExpireDate": "License Expire Date",
    "listing": "Listing",
    "loading": "로딩",
    "localTitle": "Local Title",
    "localized": "Localized",
    "male": "Male",
    "management": "Journalists Control",
    "materials": "재료",
    "materials.exclusives": "독점",
    "materials.feature": "특징",
    "materials.posters": "포스터",
    "mediaType": "Media Type",
    "move": "Move",
    "movies": "Movies",
    "moving": "Moving",
    "name": "Name",
    "new": "new",
    "newApplicant": "New Application",
    "newAssets": "새 자산",
    "newLocalTitle": "New Local Title",
    "newPassword": "New Password",
    "newRating": "New Rating",
    "newReleaseDate": "New Release Date",
    "newRunningTime": "New Running Time",
    "news": "소식",
    "next": "Next",
    "next to materials to download": "다운로드할 자료 옆에",
    "nextTab": "Next Tab",
    "noData": "죄송합니다, 우리는 당신을 위해 아무것도",
    "noRecordFound": "No record can be found",
    "oldPassword": "Old Password",
    "operationSuccess": "Operation Success",
    "other": "Other",
    "postcode": "Postcode",
    "poster": "포스터",
    "posterConfirm": "Are you sure to select this poster?",
    "posters": "Posters",
    "pressRelease": "Press Release",
    "previousTab": "Previous Tab",
    "privacyPolicy": "개인 정보 정책",
    "productId": "Product Id",
    "production": "생산",
    "productionCompany": "Production Company",
    "rated": "Rated",
    "rating": "Rating",
    "recordCreated": "Record Created",
    "recordUpdated": "Record Updated",
    "rejectApplicant": "Reject Application",
    "rejectedBy": "Rejected by",
    "rejectedComment": "Rejected Comment",
    "rejectedDate": "Rejected Date",
    "rejectedJournalist": "Rejected Journalist",
    "releaseDate": "Release Date",
    "retired": "Retired",
    "runningTime": "Running Time",
    "save": "저장",
    "saving": "절약",
    "screenplay": "Screenplay",
    "select": "Select This",
    "selectCountryOfOrigin": "Please select country of origin",
    "selectLocation": "Please select a location first",
    "selectOption": "Please select an option first",
    "selected": "Selected",
    "selectedPoster": "Selected Poster",
    "send": "Send",
    "sending": "Sending",
    "signup.acknowledge18Warning": "Please acknowledge a8 years of age or above to proceed",
    "signup.acknowledgePPolicyWarning": "Please agree on the privacy policy to proceed",
    "signup.acknowledgeTNCWarning": "Please agree on the terms and conditions to proceed",
    "signup.chooseCountryWarning": "Please choose a copmany to proceed",
    "signup.companySelectedLabel": "The company that you selected",
    "signup.confirmSwitchToCreateCompany": "Are you sure to switch to create company mode?  Selected company will be erased",
    "signup.confirmSwitchToSearchCompany": "Are you sure to switch to search company?  All filled in fields will be erased",
    "signup.correctFormWarning": "Please correct the form error before proceed to next page",
    "signup.formButtonLabelCreateCompany": "Click here to create new company",
    "signup.formButtonLabelSearchCompany": "Click here to search for a company",
    "signup.formCompanyName": "Company Name",
    "signup.formLabelCompanyCountry": "Select the company country",
    "signup.formLabelEmail": "Email Adrress",
    "signup.formLabelFirstName": "First Name",
    "signup.formLabelLastCountryCitizenship": "Country Citizenship",
    "signup.formLabelLastName": "Last Name",
    "signup.formLabelSearchCompany": "Search Company",
    "signup.formLabelSearchCompanyTitle": "or enter company name to search and select it",
    "signup.formSearchLanguagesPlaceHolder": "Type to search languages...",
    "signup.formSearchNoCompanyFound": "No company found / Please try other search text",
    "signup.formSearching": "Searching",
    "signup.formTabCompanyInformation": "Company Information",
    "signup.formTabDemographic": "Demographic",
    "signup.formThanksyou": "Form submitted successfully, you will be contacted shortly about this application",
    "signup.header": "New User Registration",
    "signup.iAdknowledgeIm18": "I acknowledge that I'm 18 years of age or above",
    "signup.iAgreeToPolicy": "I agree to the privacy policy",
    "signup.iAgreeToTheTerms": "I agree to the terms and conditions",
    "signup.labelDemographic": "Demographic",
    "signup.languages": "Languages",
    "signup.loadingPolicy": "Loading Policy",
    "signup.loadingTerms": "Loading Terms",
    "signup.pageMenu1": "I'm not robot",
    "signup.pageMenu2": "Register New User",
    "signup.pageMenu3": "Choose Your Outlet",
    "signup.pageMenu4": "Terms and Conditions",
    "signup.pageMenu5": "Submitted",
    "signup.pageTitle": null,
    "signup.privacyPolicy": "Privacy Policy",
    "signup.selectCountryWarning": "Please select a country to proceed",
    "signup.selectedCompanyLabel": "Selected Company",
    "signup.verifyCaptchaWarning": "Please verify the captcha before proceed to next page",
    "specialShoots": "스페셜 촬영",
    "state": "State",
    "status": "Status",
    "submit": "Submit",
    "submitting": "Submitting",
    "summary": "Summary",
    "synopsis": "Synopsis",
    "termsAndConditions": "이용약관",
    "territoryOfficeName": "Territory Office Name",
    "title": "Title",
    "today": "Today",
    "useLocalized": "Use localized",
    "website": "Website",
    "writer": "Writer",
    "written": "쓴"
  }], ["zh-hans", {
    "18YearsOrAbove": "18 years of age or above",
    "Active": "活跃",
    "Add Event": "添加活动",
    "Assets": "资产",
    "Calendar": "日历",
    "Coming Soon": "即将上映",
    "Content Management": "内容管理",
    "Country of Origin": "原产地",
    "Directed By": "导演是",
    "Director": "导演",
    "Download All": "全部下载",
    "Downloads": "下载",
    "Draft": "草稿",
    "Edit Event": "编辑活动",
    "Embargo Agreement Date": "禁运协议日期",
    "Embargo In Effect": "禁运生效",
    "Embargo Release Date": "禁运发布日期",
    "Embargo Start Date": "禁运开始日期",
    "End Date": "结束日期",
    "Event Location": "活动地点",
    "Event Name": "活动名称",
    "Event Type": "活动类型",
    "Events Management": "活动管理",
    "Film": "电影",
    "Genre": "类型",
    "Global": "全球的",
    "Headline": "标题",
    "Inactive": "不活跃",
    "Instruction": "操作说明",
    "Journalists Management": "记者管理",
    "Language": "语言",
    "Last Updated At": "最后更新于",
    "License Expire Date": "许可证到期日期",
    "Local": "当地的",
    "Local Title": "本地名称",
    "Localized": "本地化",
    "Marketing Automation": "营销自动化",
    "Messages": "留言",
    "Movies": "电影",
    "New": "新的",
    "New Local Title": "新的本地标题",
    "New Materials": "新资产",
    "New Rating": "新评级",
    "New Release Date": "新发布日期",
    "New Running Time": "新运行时间",
    "Our Country": "我們的國家",
    "Please click on": "请点击",
    "Posters": "海报",
    "Product Id": "产品编号",
    "Production Company": "制作公司",
    "Rated": "额定",
    "Rating": "评分",
    "Release Date": "发布日期",
    "Release Dates": "发布日期",
    "Retired": "退役",
    "Running Time": "运行时间",
    "SAVE AMERICA": "拯救美國",
    "Screenplay": "剧本",
    "Select Language": "选择语言",
    "Selected Poster": "精选海报",
    "Share Screen": "共享屏幕",
    "Start Date": "开始日期",
    "Status": "状态",
    "Summary": "摘要",
    "Synopsis": "说明书",
    "Territory": "地区办事处",
    "Timeline": "时间线",
    "Title": "标题",
    "Topics": "话题",
    "Upcoming Events": "接下来的活动",
    "Upload": "上传",
    "Upload Assets": "上传资产",
    "Use localized": "使用本地化",
    "Users Management": "用户管理",
    "Video Conference": "视频会议",
    "Welcome": "欢迎",
    "Writer": "作家",
    "active": "Active",
    "activePoster": "活动海报",
    "address": "Address",
    "adkSelectDropdown.selectAnOption": "Select an option",
    "agreeOn": "agree on",
    "app.admin": "行政",
    "app.chinese": "中文",
    "app.embargo": "禁运",
    "app.english": "English",
    "app.french": "法语",
    "app.german": "德语",
    "app.headquarters": "总部",
    "app.home": "主页",
    "app.japanese": "日语",
    "app.journalistsWebsite": "记者网站",
    "app.korean": "Korean",
    "app.listOfMovies": "电影列表",
    "app.timeline": "时间线",
    "approvedBy": "Approved by",
    "approvedComment": "Approved Comment",
    "audioVisual": "视听",
    "availablePosters": "可用海报",
    "back": "Back",
    "behindTheScenes": "幕后花絮",
    "cancel": "取消",
    "cancelConfirm": "Are you sure to cancel the changes (data will be reset)?",
    "check": "Check",
    "citizenOf": "Citizen Of",
    "city": "City",
    "close": "Close",
    "companyEmail": "Company Email",
    "companyInfo": "Company Information",
    "companyName": "Company Name",
    "confirmCancelOperation": "Are you sure to cancel this operation?",
    "confirmCreateTerritoryOffice": "Are you sure to create this territory office?",
    "confirmMoveOperation": "Are you sure to move this user?",
    "confirmPassword": "Confirm Password",
    "confirmRemoveAsset": "Are you sure to remove this asset?",
    "confirmSendOperation": "Are you sure to send this email?",
    "confirmUpdateTerritoryOffice": "Are you sure to update this territory office?",
    "contact": "Contact",
    "copyToLocal": "Copy to local",
    "country": "Country",
    "countryOfOrigin": "Country of Origin",
    "countryOfOriginSelect": "Please select country of origin",
    "credit": "归功于",
    "date": "日期",
    "dateApproved": "Date Approved",
    "dateAvailable": "可用日期",
    "dateCreated": "Date created",
    "dateExpires": "到期日期",
    "dateSentIn": "Date sent in",
    "description": "描述",
    "directedBy": "Directed By",
    "director": "Director",
    "done": "Done",
    "downloadAll": "Download All",
    "draft": "Draft",
    "edit": "修改",
    "email": "Email",
    "emailIsAllow": "Email is allowed",
    "errorDateBigger": "到期日期应该大于可用日期",
    "errorDateSmaller": "到期日期应小于可用日期",
    "errors": {
      "acknowledge-empty": "*You must agree with these agreement",
      "citizenship-empty": "*Citizenship is required",
      "companyAddress-empty": "*Address is required",
      "companyCity-empty": "*City is required",
      "companyName-empty": "*Company name is required",
      "companyState-empty": "*State is required",
      "country-empty": "*Country is required",
      "email-empty": "*Email address is required",
      "email-regex": "*Email does not match required format",
      "enter-new-email-address": "Enter new email address",
      "firstName-empty": "*First name is required",
      "invalid-email-address": "Invalid email adress",
      "languages-empty": "*Lang type is required",
      "lastName-empty": "*Last name is required",
      "mediaType-empty": "*Media type is required",
      "status-not-correct": "status is not correct",
      "territoryForOffice-empty": "*At least one territory location is required",
      "territoryOfficeName-empty": "*Territory office name is required"
    },
    "events": "活动",
    "feature.availableFilms": "可用海报",
    "feature.availablePictures": "可用图片",
    "feature.selectedPicture": "所選圖片",
    "featuredFilms": "精选影片",
    "female": "Female",
    "filmInformation": "电影信息",
    "firstName": "First Name",
    "formLabelAddTerritoryOfficeCountry": "Add a location to the territory office",
    "formLabelTerritoryOfficeName": "Territory Office Name",
    "frequently": "Frequently",
    "genre": "Genre",
    "inactive": "Inactive",
    "info": "Information",
    "journalistApplication": "Journalist Application",
    "journalistGroup": "Journalist Group",
    "journalistInfo": "Journalist Information",
    "journalists": "Journalists",
    "language": "Language",
    "lastName": "Last Name",
    "lastUpdatedAt": "Last Updated At",
    "licenseExpireDate": "License Expire Date",
    "listing": "Listing",
    "loading": "加载",
    "localTitle": "Local Title",
    "localized": "Localized",
    "male": "Male",
    "management": "Journalists Control",
    "materials": "材料",
    "materials.exclusives": "独家发售",
    "materials.feature": "精选",
    "materials.posters": "海报",
    "mediaType": "Media Type",
    "move": "Move",
    "movies": "Movies",
    "moving": "Moving",
    "name": "Name",
    "new": "new",
    "newApplicant": "New Application",
    "newAssets": "新资产",
    "newLocalTitle": "New Local Title",
    "newPassword": "New Password",
    "newRating": "New Rating",
    "newReleaseDate": "New Release Date",
    "newRunningTime": "New Running Time",
    "news": "新闻",
    "next": "Next",
    "next to materials to download": "图标进行下载",
    "nextTab": "Next Tab",
    "noData": "对不起，我们没有任何东西给你",
    "noRecordFound": "No record can be found",
    "oldPassword": "Old Password",
    "operationSuccess": "Operation Success",
    "other": "Other",
    "postcode": "Postcode",
    "poster": "海报",
    "posterConfirm": "你确定选择这张海报吗?",
    "posters": "Posters",
    "pressRelease": "Press Release",
    "previousTab": "Previous Tab",
    "privacyPolicy": "隐私政策",
    "productId": "Product Id",
    "production": "生产",
    "productionCompany": "Production Company",
    "rated": "Rated",
    "rating": "Rating",
    "recordCreated": "Record Created",
    "recordUpdated": "Record Updated",
    "rejectApplicant": "Reject Application",
    "rejectedBy": "Rejected by",
    "rejectedComment": "Rejected Comment",
    "rejectedDate": "Rejected Date",
    "rejectedJournalist": "Rejected Journalist",
    "releaseDate": "Release Date",
    "retired": "Retired",
    "runningTime": "Running Time",
    "save": "保存",
    "saving": "保存中",
    "screenplay": "Screenplay",
    "select": "Select This",
    "selectCountryOfOrigin": "Please select country of origin",
    "selectLocation": "Please select a location first",
    "selectOption": "Please select an option first",
    "selected": "Selected",
    "selectedPoster": "Selected Poster",
    "send": "Send",
    "sending": "Sending",
    "signup.acknowledge18Warning": "Please acknowledge a8 years of age or above to proceed",
    "signup.acknowledgePPolicyWarning": "Please agree on the privacy policy to proceed",
    "signup.acknowledgeTNCWarning": "Please agree on the terms and conditions to proceed",
    "signup.chooseCountryWarning": "Please choose a copmany to proceed",
    "signup.companySelectedLabel": "The company that you selected",
    "signup.confirmSwitchToCreateCompany": "Are you sure to switch to create company mode?  Selected company will be erased",
    "signup.confirmSwitchToSearchCompany": "Are you sure to switch to search company?  All filled in fields will be erased",
    "signup.correctFormWarning": "Please correct the form error before proceed to next page",
    "signup.formButtonLabelCreateCompany": "Click here to create new company",
    "signup.formButtonLabelSearchCompany": "Click here to search for a company",
    "signup.formCompanyName": "Company Name",
    "signup.formLabelCompanyCountry": "Select the company country",
    "signup.formLabelEmail": "Email Adrress",
    "signup.formLabelFirstName": "First Name",
    "signup.formLabelLastCountryCitizenship": "Country Citizenship",
    "signup.formLabelLastName": "Last Name",
    "signup.formLabelSearchCompany": "Search Company",
    "signup.formLabelSearchCompanyTitle": "or enter company name to search and select it",
    "signup.formSearchLanguagesPlaceHolder": "Type to search languages...",
    "signup.formSearchNoCompanyFound": "No company found / Please try other search text",
    "signup.formSearching": "Searching",
    "signup.formTabCompanyInformation": "Company Information",
    "signup.formTabDemographic": "Demographic",
    "signup.formThanksyou": "Form submitted successfully, you will be contacted shortly about this application",
    "signup.header": "New User Registration",
    "signup.iAdknowledgeIm18": "I acknowledge that I'm 18 years of age or above",
    "signup.iAgreeToPolicy": "I agree to the privacy policy",
    "signup.iAgreeToTheTerms": "I agree to the terms and conditions",
    "signup.labelDemographic": "Demographic",
    "signup.languages": "Languages",
    "signup.loadingPolicy": "Loading Policy",
    "signup.loadingTerms": "Loading Terms",
    "signup.pageMenu1": "I'm not robot",
    "signup.pageMenu2": "Register New User",
    "signup.pageMenu3": "Choose Your Outlet",
    "signup.pageMenu4": "Terms and Conditions",
    "signup.pageMenu5": "Submitted",
    "signup.pageTitle": null,
    "signup.privacyPolicy": "Privacy Policy",
    "signup.selectCountryWarning": "Please select a country to proceed",
    "signup.selectedCompanyLabel": "Selected Company",
    "signup.verifyCaptchaWarning": "Please verify the captcha before proceed to next page",
    "specialShoots": "特别拍摄",
    "state": "State",
    "status": "Status",
    "submit": "Submit",
    "submitting": "Submitting",
    "summary": "Summary",
    "synopsis": "Synopsis",
    "termsAndConditions": "条款和条件",
    "territoryOfficeName": "Territory Office Name",
    "title": "Title",
    "today": "今日",
    "useLocalized": "Use localized",
    "website": "Website",
    "writer": "Writer",
    "written": "书面"
  }]];
  _exports.default = _default;
});